import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
import { TicketCategory } from "~/types/ticketcategory";
import { Location } from "~/types/location";
export var Event = function Event() {
  _classCallCheck(this, Event);

  this.entity_type_id = 20;
  this.attribute_set_id = 21;
  this.created_at = new Date();
  this.updated_at = new Date();
  this.location = new Location();
};
export var EventRequest = function EventRequest() {
  _classCallCheck(this, EventRequest);

  this.information = new Event();
  this.category = new TicketCategory();
};