import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.link";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { EventDate } from "~/utils/Classes/EventDate";
import { MediaItem } from "~/utils/Classes/MediaItem";
import { MediaEvent } from "~/utils/Classes/MediaEvent";
import { MediaItemUser } from "~/utils/Classes/MediaItemUser";
import { Media } from "~/utils/Media";
export var name = 'media';
export var state = function state() {
  return {
    eventDates: [],
    events: [],
    eventDatesWithoutAccess: [],
    eventDatesWithAccess: [],
    eventsWithoutFileAccess: [],
    eventsWithFileAccess: [],
    mediaItemUsersWithAccess: [],
    mediaItemUsersWithOutAccess: [],
    mediaItems: [],
    accessibleMedia: [],
    notAccessibleMedia: [],
    selectedMediaId: null,
    selectedEventDateId: null,
    mediaItem: {
      id: '',
      title: '',
      link: '',
      html: '',
      tags: [],
      speakers: []
    },
    submitType: 'create',
    isSearchingEventDates: true,
    isMediaSearch: true,
    hasPendingChanges: false,
    isInitialSet: true,
    showPendingChangesModal: false
  };
};
export var mutations = {
  SET_SEARCHING_EVENT_DATES: function SET_SEARCHING_EVENT_DATES(state, value) {
    state.isSearchingEventDates = value;
  },
  SET_MEDIA_SEARCH: function SET_MEDIA_SEARCH(state, value) {
    state.isMediaSearch = value;
  },
  SET_SELECTED_MEDIA_ID: function SET_SELECTED_MEDIA_ID(state, mediaItemId) {
    state.selectedMediaId = mediaItemId;
  },
  SET_SELECTED_EVENT_DATE_ID: function SET_SELECTED_EVENT_DATE_ID(state, mediaItemId) {
    state.selectedEventDateId = mediaItemId;
  },
  SET_EVENT_DATES: function SET_EVENT_DATES(state, eventDates) {
    state.eventDates = eventDates;
  },
  SET_EVENTS: function SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_EVENTS_WITH_FILE_ACCESS: function SET_EVENTS_WITH_FILE_ACCESS(state, events) {
    state.eventsWithFileAccess = events;
  },
  SET_EVENT_DATES_WITHOUT_ACCESS: function SET_EVENT_DATES_WITHOUT_ACCESS(state, eventDates) {
    state.eventDatesWithoutAccess = eventDates;
  },
  SET_EVENT_DATES_WITH_ACCESS: function SET_EVENT_DATES_WITH_ACCESS(state, eventDates) {
    state.eventDatesWithAccess = eventDates;
  },
  SET_USERS_WITH_ACCESS: function SET_USERS_WITH_ACCESS(state, users) {
    state.mediaItemUsersWithAccess = users;
  },
  SET_EVENTS_WITHOUT_FILE_ACCESS: function SET_EVENTS_WITHOUT_FILE_ACCESS(state, events) {
    state.eventsWithoutFileAccess = events;
  },
  SET_MEDIA_ITEMS: function SET_MEDIA_ITEMS(state, media) {
    state.mediaItems = media;
  },
  SET_NOT_ACCESSIBLE_MEDIA: function SET_NOT_ACCESSIBLE_MEDIA(state, mediaItems) {
    state.notAccessibleMedia = mediaItems;
  },
  SET_MEDIA_ITEM: function SET_MEDIA_ITEM(state, mediaItem) {
    state.mediaItem = mediaItem;
  },
  SET_SUBMIT_TYPE: function SET_SUBMIT_TYPE(state, type) {
    state.submitType = type;
  },
  UPDATE_TITLE: function UPDATE_TITLE(state, title) {
    state.mediaItem.title = title;
  },
  SET_TAGS: function SET_TAGS(state, tags) {
    state.mediaItem.tags = tags;
  },
  SET_SPEAKERS: function SET_SPEAKERS(state, speakers) {
    state.mediaItem.speakers = speakers;
  },
  UPDATE_LINK: function UPDATE_LINK(state, link) {
    state.mediaItem.link = link;
  },
  UPDATE_HTML: function UPDATE_HTML(state, html) {
    state.mediaItem.html = html;
  },
  CLEAR_DOCUMENT: function CLEAR_DOCUMENT(state) {
    state.mediaItem = Media.create();
  },
  CLEAR_CLEAR_EVENT_DATES: function CLEAR_CLEAR_EVENT_DATES(state) {
    state.eventDates = [];
  },
  ADD_FILE_TO_ACCESSIBLE_LIST: function ADD_FILE_TO_ACCESSIBLE_LIST(state, mediaItem) {
    state.mediaItems.push(mediaItem);
  },
  ADD_FILE_TO_NOT_ACCESSIBLE_LIST: function ADD_FILE_TO_NOT_ACCESSIBLE_LIST(state, mediaItem) {
    state.notAccessibleMedia.push(mediaItem);
  },
  ADD_TO_EVENT_DATES_WITH_FILE_ACCESS: function ADD_TO_EVENT_DATES_WITH_FILE_ACCESS(state, eventDate) {
    state.eventDatesWithAccess.push(eventDate);
  },
  ADD_TO_USERS_WITH_FILE_ACCESS: function ADD_TO_USERS_WITH_FILE_ACCESS(state, user) {
    state.mediaItemUsersWithAccess.push(user);
  },
  ADD_TO_EVENT_DATES_WITHOUT_FILE_ACCESS: function ADD_TO_EVENT_DATES_WITHOUT_FILE_ACCESS(state, eventDate) {
    state.eventDatesWithoutAccess.push(eventDate);
  },
  ADD_TO_EVENTS_WITHOUT_FILE_ACCESS: function ADD_TO_EVENTS_WITHOUT_FILE_ACCESS(state, event) {
    state.eventsWithoutFileAccess.push(event);
  },
  ADD_TO_EVENTS_WITH_DOCUMENT_ACCESS: function ADD_TO_EVENTS_WITH_DOCUMENT_ACCESS(state, event) {
    state.eventsWithFileAccess.push(event);
  },
  CLEAR_THUMBNAIL_SETTINGS: function CLEAR_THUMBNAIL_SETTINGS(state, event) {
    state.mediaItem.useYoutubeThumbnail = false;
    state.mediaItem.useVimeoThumbnail = false;
  },
  SET_HAS_PENDING_CHANGES: function SET_HAS_PENDING_CHANGES(state, value) {
    state.hasPendingChanges = value;
  },
  SET_IS_INITIAL_SET: function SET_IS_INITIAL_SET(state, value) {
    state.isInitialSet = value;
  },
  SET_SHOW_PENDING_CHANGES_MODAL: function SET_SHOW_PENDING_CHANGES_MODAL(state, value) {
    state.showPendingChangesModal = value;
  }
};
export var actions = {
  setEventDatesWithFileAccess: function setEventDatesWithFileAccess(_ref, eventDateModels) {
    var commit = _ref.commit;
    var eventDates = [];

    var _iterator = _createForOfIteratorHelper(eventDateModels),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var eventDate = _step.value;
        eventDates.push(new EventDate(eventDate));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    commit('SET_EVENT_DATES_WITH_ACCESS', eventDates);
  },
  setUsersWithFileAccess: function setUsersWithFileAccess(_ref2, userModels) {
    var commit = _ref2.commit;
    var users = [];

    var _iterator2 = _createForOfIteratorHelper(userModels),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var user = _step2.value;
        users.push(new MediaItemUser(user));
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    commit('SET_USERS_WITH_ACCESS', users);
  },
  setEventDates: function setEventDates(_ref3, eventDateModels) {
    var commit = _ref3.commit;
    var eventDates = [];

    var _iterator3 = _createForOfIteratorHelper(eventDateModels),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var eventDate = _step3.value;
        eventDates.push(new EventDate(eventDate));
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    commit('SET_EVENT_DATES', eventDates);
  },
  setEvents: function setEvents(_ref4, eventModels) {
    var commit = _ref4.commit;
    var events = [];

    var _iterator4 = _createForOfIteratorHelper(eventModels),
        _step4;

    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var eventModel = _step4.value;
        events.push(new MediaEvent(eventModel));
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }

    commit('SET_EVENTS', events);
  },
  setEventsWithFileAccess: function setEventsWithFileAccess(_ref5, eventModels) {
    var commit = _ref5.commit;
    var events = [];

    var _iterator5 = _createForOfIteratorHelper(eventModels),
        _step5;

    try {
      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
        var eventModel = _step5.value;
        events.push(new MediaEvent(eventModel));
      }
    } catch (err) {
      _iterator5.e(err);
    } finally {
      _iterator5.f();
    }

    commit('SET_EVENTS_WITH_FILE_ACCESS', events);
  },
  setEventDatesWithoutFileAccess: function setEventDatesWithoutFileAccess(_ref6, eventDateModels) {
    var commit = _ref6.commit;
    var eventDates = [];

    var _iterator6 = _createForOfIteratorHelper(eventDateModels),
        _step6;

    try {
      for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
        var eventDate = _step6.value;
        eventDates.push(new EventDate(eventDate));
      }
    } catch (err) {
      _iterator6.e(err);
    } finally {
      _iterator6.f();
    }

    commit('SET_EVENT_DATES_WITHOUT_ACCESS', eventDates);
  },
  setEventsWithoutDocumentAccess: function setEventsWithoutDocumentAccess(_ref7, eventModels) {
    var commit = _ref7.commit;
    var events = [];

    var _iterator7 = _createForOfIteratorHelper(eventModels),
        _step7;

    try {
      for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
        var event = _step7.value;
        events.push(new MediaEvent(event));
      }
    } catch (err) {
      _iterator7.e(err);
    } finally {
      _iterator7.f();
    }

    commit('SET_EVENTS_WITHOUT_FILE_ACCESS', events);
  },
  clearEventDates: function clearEventDates(_ref8) {
    var commit = _ref8.commit,
        state = _ref8.state;
    commit('CLEAR_CLEAR_EVENT_DATES');
  },
  setMediaItems: function setMediaItems(_ref9, mediaItemModels) {
    var commit = _ref9.commit;
    var mediaItems = [];

    var _iterator8 = _createForOfIteratorHelper(mediaItemModels),
        _step8;

    try {
      for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
        var mediaItem = _step8.value;
        mediaItems.push(new Media(mediaItem));
      }
    } catch (err) {
      _iterator8.e(err);
    } finally {
      _iterator8.f();
    }

    commit('SET_MEDIA_ITEMS', mediaItems);
  },
  setMediaItem: function setMediaItem(_ref10, mediaItem) {
    var commit = _ref10.commit;
    commit('SET_MEDIA_ITEM', mediaItem);
  },
  setNotAccessibleMedia: function setNotAccessibleMedia(_ref11, mediaItemModels) {
    var commit = _ref11.commit;
    var mediaItems = [];

    var _iterator9 = _createForOfIteratorHelper(mediaItemModels),
        _step9;

    try {
      for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
        var mediaItem = _step9.value;
        mediaItems.push(new MediaItem(mediaItem));
      }
    } catch (err) {
      _iterator9.e(err);
    } finally {
      _iterator9.f();
    }

    commit('SET_NOT_ACCESSIBLE_MEDIA', mediaItems);
  },
  setTags: function setTags(_ref12, tags) {
    var commit = _ref12.commit,
        state = _ref12.state;
    commit('SET_TAGS', tags);
  },
  setSpeakers: function setSpeakers(_ref13, speakers) {
    var commit = _ref13.commit,
        state = _ref13.state;
    commit('SET_SPEAKERS', speakers);
  },
  setSubmitType: function setSubmitType(_ref14, type) {
    var commit = _ref14.commit,
        state = _ref14.state;
    commit('SET_SUBMIT_TYPE', type);
  },
  clearDocument: function clearDocument(_ref15) {
    var commit = _ref15.commit,
        state = _ref15.state;
    commit('CLEAR_DOCUMENT');
  },
  updateTitle: function updateTitle(_ref16, title) {
    var commit = _ref16.commit,
        state = _ref16.state;
    commit('UPDATE_TITLE', title);
  },
  updateLink: function updateLink(_ref17, link) {
    var commit = _ref17.commit,
        state = _ref17.state;
    commit('UPDATE_LINK', link);
  },
  updateHtml: function updateHtml(_ref18, html) {
    var commit = _ref18.commit,
        state = _ref18.state;
    commit('UPDATE_HTML', html);
  },
  setSearchingEventDates: function setSearchingEventDates(_ref19, value) {
    var commit = _ref19.commit,
        state = _ref19.state;
    commit('SET_SEARCHING_EVENT_DATES', value);
  },
  setIsMediaSearch: function setIsMediaSearch(_ref20, value) {
    var commit = _ref20.commit,
        state = _ref20.state;
    commit('SET_MEDIA_SEARCH', value);
  },
  setSelectedMediaId: function setSelectedMediaId(_ref21, mediaItemId) {
    var commit = _ref21.commit,
        state = _ref21.state;
    commit('SET_SELECTED_MEDIA_ID', mediaItemId);
  },
  setSelectedEventDateId: function setSelectedEventDateId(_ref22, eventDateId) {
    var commit = _ref22.commit,
        state = _ref22.state;
    commit('SET_SELECTED_EVENT_DATE_ID', eventDateId);
  },
  removeMediaAccess: function removeMediaAccess(_ref23, mediaItemId) {
    var commit = _ref23.commit,
        state = _ref23.state;
    var updatedMedia = state.mediaItems.filter(function (mediaItem) {
      return mediaItem.id != mediaItemId;
    });
    commit('SET_MEDIA', updatedMedia);
  },
  removeFromNotAccessibleMedia: function removeFromNotAccessibleMedia(_ref24, mediaItemId) {
    var commit = _ref24.commit,
        state = _ref24.state;
    var updatedNotAccessibleMedia = state.notAccessibleMedia.filter(function (mediaItem) {
      return mediaItem.id != mediaItemId;
    });
    commit('SET_NOT_ACCESSIBLE_MEDIA', updatedNotAccessibleMedia);
  },
  addMediaAccess: function addMediaAccess(_ref25, mediaItem) {
    var commit = _ref25.commit,
        state = _ref25.state;
    commit('ADD_FILE_TO_ACCESSIBLE_LIST', mediaItem);
  },
  addToNotAccessibleMedia: function addToNotAccessibleMedia(_ref26, mediaItem) {
    var commit = _ref26.commit,
        state = _ref26.state;
    commit('ADD_FILE_TO_NOT_ACCESSIBLE_LIST', mediaItem);
  },
  addToEventsWithFileAccess: function addToEventsWithFileAccess(_ref27, event) {
    var commit = _ref27.commit,
        state = _ref27.state;
    commit('ADD_TO_EVENTS_WITH_DOCUMENT_ACCESS', event);
  },
  addToEventDatesWithFileAccess: function addToEventDatesWithFileAccess(_ref28, eventDate) {
    var commit = _ref28.commit,
        state = _ref28.state;
    commit('ADD_TO_EVENT_DATES_WITH_FILE_ACCESS', eventDate);
  },
  addToUsersWithFileAccess: function addToUsersWithFileAccess(_ref29, user) {
    var commit = _ref29.commit,
        state = _ref29.state;
    commit('ADD_TO_USERS_WITH_FILE_ACCESS', user);
  },
  addToEventDatesWithOutFileAccess: function addToEventDatesWithOutFileAccess(_ref30, eventDate) {
    var commit = _ref30.commit,
        state = _ref30.state;
    commit('ADD_TO_EVENT_DATES_WITHOUT_FILE_ACCESS', eventDate);
  },
  addToEventFileWithOutAccess: function addToEventFileWithOutAccess(_ref31, eventDate) {
    var commit = _ref31.commit,
        state = _ref31.state;
    commit('ADD_TO_EVENTS_WITHOUT_FILE_ACCESS', eventDate);
  },
  removeFromEventFilesWithAccess: function removeFromEventFilesWithAccess(_ref32, eventId) {
    var commit = _ref32.commit,
        state = _ref32.state;
    var updatedEvents = state.eventsWithFileAccess.filter(function (event) {
      return event.event_id != eventId;
    });
    commit('SET_EVENTS_WITH_FILE_ACCESS', updatedEvents);
  },
  removeFromMediaUsersWithAccess: function removeFromMediaUsersWithAccess(_ref33, email) {
    var commit = _ref33.commit,
        state = _ref33.state;
    var updatedUsers = state.mediaItemUsersWithAccess.filter(function (user) {
      return user.email != email;
    });
    commit('SET_USERS_WITH_ACCESS', updatedUsers);
  },
  removeFromEventDatesWithoutAccess: function removeFromEventDatesWithoutAccess(_ref34, eventDateId) {
    var commit = _ref34.commit,
        state = _ref34.state;
    var updatedEventDates = state.eventDatesWithoutAccess.filter(function (eventDate) {
      return eventDate.event_date_id != eventDateId;
    });
    commit('SET_EVENT_DATES_WITHOUT_ACCESS', updatedEventDates);
  },
  removeFromEventDateFilesWithAccess: function removeFromEventDateFilesWithAccess(_ref35, eventDateId) {
    var commit = _ref35.commit,
        state = _ref35.state;
    var updatedEventDates = state.eventDatesWithAccess.filter(function (eventDate) {
      return eventDate.event_date_id != eventDateId;
    });
    commit('SET_EVENT_DATES_WITH_ACCESS', updatedEventDates);
  },
  removeFromEventsWithoutFileAccess: function removeFromEventsWithoutFileAccess(_ref36, eventId) {
    var commit = _ref36.commit,
        state = _ref36.state;
    var eventsWithoutFileAccess = state.eventsWithoutFileAccess.filter(function (event) {
      return event.event_id != eventId;
    });
    commit('SET_EVENTS_WITHOUT_FILE_ACCESS', eventsWithoutFileAccess);
  },
  clearThumbnailSettings: function clearThumbnailSettings(_ref37) {
    var commit = _ref37.commit,
        state = _ref37.state;
    commit('CLEAR_THUMBNAIL_SETTINGS');
  },
  setHasPendingChanges: function setHasPendingChanges(_ref38, value) {
    var commit = _ref38.commit,
        state = _ref38.state;
    commit('SET_HAS_PENDING_CHANGES', value);
  },
  setIsInitialSet: function setIsInitialSet(_ref39, value) {
    var commit = _ref39.commit,
        state = _ref39.state;
    commit('SET_IS_INITIAL_SET', value);
  },
  setShowPendingChangesModal: function setShowPendingChangesModal(_ref40, value) {
    var commit = _ref40.commit,
        state = _ref40.state;
    console.log(value + ' is set');
    commit('SET_SHOW_PENDING_CHANGES_MODAL', value);
  }
};