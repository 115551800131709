import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import { Vue } from "~/node_modules/vue-property-decorator";
export var name = 'draggable';
export var state;
export var mutations = {
  START_DRAGGING: function START_DRAGGING(state, draggableContent) {
    state.draggedContent = draggableContent;
    state.isDragging = true;
  },
  STOP_DRAGGING: function STOP_DRAGGING(state) {
    state.draggedContent = {};
    state.isDragging = false;
  },
  SET_EDIT_MODE: function SET_EDIT_MODE(state, val) {
    state.editMode = val;
  },
  SET_TAB: function SET_TAB(state, name) {
    var wrapper = state.dashboardData.find(function (wrapper) {
      return wrapper.name == name;
    });
    Vue.set(wrapper.tabs, state.draggableContent.name, state.draggableContent);
  },
  SET_DATA: function SET_DATA(state, name) {
    var wrapper = state.dashboardData.find(function (wrapper) {
      return wrapper.name == name;
    });
    Vue.set(wrapper.tabs, state.draggableContent.name, state.draggableContent);
  }
};
export var actions = {
  onDragStart: function onDragStart(_ref, data) {
    var state = _ref.state,
        commit = _ref.commit;

    if (state.editMode) {
      commit('START_DRAGGING', data);
    }
  },
  onDropTab: function onDropTab(_ref2, name) {
    var state = _ref2.state,
        commit = _ref2.commit;

    if (state.editMode) {
      commit('SET_TAB', name);
      commit('STOP_DRAGGING');
    }
  },
  onDropData: function onDropData(_ref3, name) {
    var state = _ref3.state,
        commit = _ref3.commit;

    if (state.editMode) {
      //commit('SET_DATA',name);
      commit('STOP_DRAGGING');
    }
  }
};