import "core-js/modules/es6.function.name";
import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
export var MediaEvent = function MediaEvent(_ref) {
  var event_id = _ref.event_id,
      name = _ref.name,
      promoter_id = _ref.promoter_id,
      media_visibilities_id = _ref.media_visibilities_id;

  _classCallCheck(this, MediaEvent);

  this.event_id = event_id;
  this.name = name;
  this.promoter_id = promoter_id;
  this.media_visibilities_id = media_visibilities_id;
};