import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
export var CreditMemo = function CreditMemo(_ref) {
  var created_at = _ref.created_at,
      credit_memo_number = _ref.credit_memo_number,
      id = _ref.id,
      invoice_by_promoter_id = _ref.invoice_by_promoter_id,
      order_increment_id = _ref.order_increment_id,
      promoter_id = _ref.promoter_id,
      promoter_invoice_prefix = _ref.promoter_invoice_prefix,
      updated_at = _ref.updated_at;

  _classCallCheck(this, CreditMemo);

  this.created_at = created_at;
  this.credit_memo_number = credit_memo_number;
  this.id = id;
  this.invoice_by_promoter_id = invoice_by_promoter_id;
  this.order_increment_id = order_increment_id;
  this.promoter_id = promoter_id;
  this.promoter_invoice_prefix = promoter_invoice_prefix;
  this.updated_at = updated_at;
};