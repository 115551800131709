<template>
  <div class="container-fluid footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-center-xs">
          <p><b class="b400">Wir sind für dich da</b></p>
          <p><img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/phone.svg">
            <b class="b400"><a
              style="color: #000;" href="tel:+4952329462555">+49 5232 946 2555</a></b>
            <span class="hidden-xs">&nbsp;
              <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/mail.svg">
              <b class="b400">mail@cvents.eu</b></span></p>
          <p style="font-size: 16px;color: #777">Montag bis Freitag:<br class="visible-xs">
            09.00 - 13.00 Uhr und 13.30
            - 16.00 Uhr</p>
        </div>
        <div class="col-lg-6 hidden">
        </div>
      </div>
      <div class="row footersocial">
        <div class="hidden-xs hidden-sm col-sm-8 col-md-8 col-lg-6">
          <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/all_payments.svg">
        </div>
        <div class="col-sm-4 col-md-4 col-lg-6 text-right text-center-xs">
          <a href="https://www.facebook.com/cvents" target="_blank" rel="nofollow">
            <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/facebook.svg"></a>
          <a href="https://twitter.com/cvents"
             target="_blank" rel="nofollow">
            <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/twitter.svg"></a>
          <a
            href="https://www.instagram.com/cvents.eu/" target="_blank" rel="nofollow">
            <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/instagram.svg"></a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-8 text-center-xs">
          <p class="footerlinks">
            <a href="https://www.cvents.eu/legal/imprint/impressum_de.html" target="_blank">Impressum</a> ·
            <a href="https://www.cvents.eu/legal/terms/agb_de.html" target="_blank">AGB</a> ·
            <a href="https://www.cvents.eu/legal/privacy/datenschutz_de.html" target="_blank">Datenschutz</a> ·
            <a href="https://www.cvents.eu/legal/revocation/widerruf_de.html" target="_blank">Widerruf</a> ·
            <a href="http://www.cvents.eu/de/jobs" target="_blank">Jobs</a> ·
            <a href="http://cvts.eu/sell-tickets-de" target="_blank">Informationen für Veranstalter</a>
          </p>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-6 col-lg-4 text-right text-center-xs">
          <p class="footerlinks b400"><a href="/" class="logo">
            <img src="https://www.cvents.eu/skin/frontend/boilerplate/cvents/images/cvents.svg"></a>
            <span class="hidden-xs hidden-sm"> |&nbsp;Dein christliches Ticketportal</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>

<style scoped lang="scss">
  .footer {
    max-width: 1900px;
    margin-top: 100px;
    background: #f6f6f6;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .footer img {
    height: 30px;
  }

  .footerlinks {
    font-size: 14px;
  }

  .footerlinks a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    color: #000;
    line-height: 34px;
  }

  .b400 {
    font-weight: 400;
  }

  .footersocial {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
  }
</style>
