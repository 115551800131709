import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a941dd2 = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _249e338a = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _5a0ddc26 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _7ef46b18 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1269c66f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2909b94f = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _09d70517 = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _1226c9d4 = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _6b731f92 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _26973cc2 = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _92fcdb4c = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _042398ee = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _19a66086 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _29f99644 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _3d726c1b = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _0b46251f = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _235c8c73 = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _16e75f21 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _13f59d8a = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _774eeb90 = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _c794d7f6 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _cbee973c = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _f7f2f8e6 = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _2e13ace0 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _c8cb1c1a = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _6cf2a28a = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _53759380 = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _1b29b8be = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _1b0d89bc = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _3b2fd3ff = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _1fe1bb4f = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _20377d36 = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _115c9ed1 = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _7acfe124 = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _8eac8cf4 = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _30e6ef56 = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _00796f1a = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _0cb1cd69 = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _39f294ad = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _b672fe30 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _1a218b7c = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _0466004e = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _24525aac = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _073b73de = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _4bcf45d3 = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _a3c23612 = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _dc150970 = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _2e3f063a = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _3d3e0fd2 = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _98232350 = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _03291786 = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _268172d2 = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _5f9dd43d = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _57e8af26 = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _2858f578 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _30213208 = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _049cb43a = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _dcbf5b64 = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _21c12886 = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _68161dfe = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _23115062 = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _0a117486 = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _c85cafea = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _3558956b = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _ea3a84cc = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _459fd553 = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _41d5ef2f = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _06e6a3c4 = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _685fe2a8 = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _4b8ad1ee = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _18d4c3ba = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _7a48dfd3 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _61755abb = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _83e4f9d2 = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _5a941dd2,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _249e338a,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _5a0ddc26,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _7ef46b18,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _1269c66f,
    name: "index___en"
  }, {
    path: "/events",
    component: _2909b94f,
    name: "events___de"
  }, {
    path: "/files",
    component: _09d70517,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _1226c9d4,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _6b731f92,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _26973cc2,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _92fcdb4c,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _042398ee,
    name: "login___de"
  }, {
    path: "/logout",
    component: _19a66086,
    name: "logout___de"
  }, {
    path: "/media",
    component: _29f99644,
    name: "media___de"
  }, {
    path: "/orders",
    component: _3d726c1b,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _0b46251f,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _235c8c73,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _16e75f21,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _13f59d8a,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _774eeb90,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _c794d7f6,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _cbee973c,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _f7f2f8e6,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _2e13ace0,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _c8cb1c1a,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _6cf2a28a,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _53759380,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _1b29b8be,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _1b0d89bc,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _5a941dd2,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _249e338a,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _5a0ddc26,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _7ef46b18,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _2909b94f,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _09d70517,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _1226c9d4,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _6b731f92,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _26973cc2,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _92fcdb4c,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _042398ee,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _19a66086,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _29f99644,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _3d726c1b,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _0b46251f,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _235c8c73,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _16e75f21,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _3b2fd3ff,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _1fe1bb4f,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _20377d36,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _115c9ed1,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _7acfe124,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _8eac8cf4,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _30e6ef56,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _00796f1a,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _0cb1cd69,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _39f294ad,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _b672fe30,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _1a218b7c,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _13f59d8a,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _774eeb90,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _c794d7f6,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _cbee973c,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _f7f2f8e6,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _2e13ace0,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _c8cb1c1a,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _6cf2a28a,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _53759380,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _1b29b8be,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _1b0d89bc,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _3b2fd3ff,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _1fe1bb4f,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _20377d36,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _115c9ed1,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _7acfe124,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _8eac8cf4,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _30e6ef56,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _00796f1a,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _0cb1cd69,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _39f294ad,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _b672fe30,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _1a218b7c,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _0466004e,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _24525aac,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _073b73de,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _0466004e,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _24525aac,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _073b73de,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _4bcf45d3,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _a3c23612,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _dc150970,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _2e3f063a,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _3d3e0fd2,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _98232350,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _03291786,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _268172d2,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _5f9dd43d,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _57e8af26,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _2858f578,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _30213208,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _4bcf45d3,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _049cb43a,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _dcbf5b64,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _a3c23612,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _21c12886,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _68161dfe,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _23115062,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _0a117486,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _c85cafea,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _3558956b,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _ea3a84cc,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _459fd553,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _41d5ef2f,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _06e6a3c4,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _dc150970,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _2e3f063a,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _3d3e0fd2,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _98232350,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _03291786,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _268172d2,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _5f9dd43d,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _57e8af26,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _2858f578,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _30213208,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _685fe2a8,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _4b8ad1ee,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _18d4c3ba,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _7a48dfd3,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _049cb43a,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _dcbf5b64,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _61755abb,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _83e4f9d2,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _21c12886,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _68161dfe,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _23115062,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _0a117486,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _c85cafea,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _3558956b,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _ea3a84cc,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _459fd553,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _41d5ef2f,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _06e6a3c4,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _685fe2a8,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _4b8ad1ee,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _18d4c3ba,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _7a48dfd3,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _61755abb,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _83e4f9d2,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _1269c66f,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
