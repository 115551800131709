<template>
  <a href="/">
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="151.757px" height="49.326px" viewBox="0 0 151.757 49.326" enable-background="new 0 0 151.757 49.326"
         xml:space="preserve">
              <g>
                <path fill="#1D1D1B" d="M20.275,8.451c-0.77-0.303-1.725-0.557-2.867-0.76c-1.143-0.203-2.247-0.309-3.315-0.316
                  C9.805,7.451,6.398,8.814,3.874,11.459C1.35,14.105,0.059,17.568,0,21.85c0.02,2.674,0.619,5.098,1.798,7.273
                  c1.179,2.178,2.822,3.914,4.929,5.211c2.107,1.297,4.562,1.961,7.366,1.992c0.941,0.014,1.974-0.055,3.098-0.203
                  c1.124-0.148,2.224-0.461,3.301-0.934l-0.38-4.551c-0.808,0.516-1.7,0.908-2.678,1.174c-0.977,0.264-1.911,0.398-2.799,0.398
                  c-2.18-0.035-3.949-0.564-5.307-1.588s-2.352-2.32-2.98-3.893c-0.629-1.57-0.94-3.197-0.932-4.881
                  c0.004-1.783,0.351-3.459,1.041-5.023c0.689-1.566,1.695-2.84,3.017-3.82s2.934-1.486,4.836-1.518c0.82,0,1.702,0.098,2.644,0.293
                  s1.904,0.496,2.888,0.9L20.275,8.451L20.275,8.451z"/>
                <polygon fill="#1D1D1B" points="50.553,8.025 45.457,8.025 37.812,30.584 37.704,30.584 30.22,8.025 24.635,8.025 34.559,35.676
                  40.632,35.676 50.553,8.025 	"/>
                <path fill="#D04B38" d="M71.695,42.443c-0.689,0.566-1.843,1.158-3.462,1.771s-3.302,0.945-5.051,0.996
                  c-2.492-0.041-4.615-0.895-6.368-2.557c-1.753-1.664-2.668-3.887-2.747-6.668h19.74c0.02-2.992-0.351-5.658-1.114-8
                  c-0.763-2.34-2.038-4.188-3.825-5.541c-1.787-1.352-4.207-2.043-7.26-2.07c-3.958,0.07-7.094,1.377-9.408,3.922
                  c-2.315,2.543-3.498,5.898-3.55,10.064c-0.017,4.564,1.142,8.184,3.475,10.859c2.334,2.676,5.947,4.045,10.839,4.105
                  c2.236-0.029,4.017-0.24,5.343-0.629c1.326-0.389,2.455-0.775,3.387-1.158V42.443L71.695,42.443z"/>
                <path fill="#1D1D1B" d="M80.622,35.676h5.091V22.877c-0.013-3.416,0.664-6.154,2.028-8.215c1.364-2.062,3.492-3.119,6.382-3.174
                  c2.19,0.127,3.723,1,4.598,2.617s1.295,4.035,1.261,7.254v14.316h5.091V19.244c0.016-3.746-0.815-6.652-2.493-8.721
                  s-4.298-3.119-7.861-3.148c-1.968,0.014-3.77,0.459-5.405,1.34c-1.636,0.879-2.854,2.111-3.655,3.695H85.55V8.025h-4.928V35.676
                  L80.622,35.676z"/>
                <path fill="#1D1D1B" d="M128.452,8.025h-7.321V0l-5.091,1.625v6.4h-6.236v4.115h6.236v16.166c0.026,2.752,0.787,4.781,2.282,6.084
                  c1.496,1.301,3.571,1.947,6.225,1.936c0.822-0.012,1.605-0.098,2.353-0.258s1.409-0.326,1.986-0.5v-4.334
                  c-0.378,0.256-0.854,0.479-1.431,0.672c-0.574,0.193-1.255,0.295-2.04,0.305c-1.198-0.006-2.204-0.428-3.017-1.268
                  s-1.235-2.062-1.268-3.668V12.141h7.321V8.025L128.452,8.025z"/>
                <path fill="#1D1D1B" d="M134.519,34.811c1.172,0.607,2.402,1.016,3.69,1.225s2.587,0.307,3.897,0.291
                  c1.674,0.004,3.24-0.303,4.697-0.92s2.641-1.562,3.549-2.836s1.377-2.895,1.404-4.861c-0.062-2.164-0.687-3.812-1.872-4.945
                  c-1.187-1.133-2.56-2.027-4.12-2.684c-1.561-0.654-2.934-1.35-4.119-2.082c-1.187-0.732-1.811-1.781-1.873-3.145
                  c0.041-1.139,0.529-1.984,1.465-2.537c0.938-0.555,2.077-0.83,3.421-0.828c0.675,0.016,1.576,0.162,2.704,0.441
                  s2.016,0.604,2.662,0.971l0.433-4.449c-0.991-0.303-2.001-0.557-3.026-0.76c-1.026-0.203-2.132-0.309-3.315-0.316
                  c-2.804-0.006-5.116,0.695-6.935,2.098c-1.818,1.404-2.761,3.541-2.824,6.41c0.062,1.904,0.687,3.402,1.872,4.496
                  c1.187,1.094,2.56,1.996,4.12,2.705c1.561,0.711,2.934,1.443,4.119,2.197c1.187,0.754,1.811,1.744,1.873,2.971
                  c-0.071,1.471-0.648,2.504-1.73,3.098c-1.082,0.596-2.242,0.883-3.48,0.861c-0.781,0-1.77-0.162-2.969-0.488
                  c-1.199-0.324-2.322-0.812-3.371-1.463L134.519,34.811L134.519,34.811z"/>
              </g>
              </svg>
  </a>
</template>
<script>
  export default {
    name: 'Logo',
  }
</script>

<style scoped></style>
