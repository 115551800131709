export var name = 'users';
export var state = function state() {
  return {
    users: [],
    selectedPromoterId: null
  };
};
export var mutations = {
  SET_USERS: function SET_USERS(state, users) {
    state.users = users;
  },
  SET_PROMOTER_ID: function SET_PROMOTER_ID(state, promoterId) {
    state.selectedPromoterId = promoterId;
  }
};
export var actions = {
  setUsers: function setUsers(_ref, users) {
    var commit = _ref.commit,
        state = _ref.state;
    commit('SET_USERS', users);
  },
  setPromoterId: function setPromoterId(_ref2, promoterId) {
    var commit = _ref2.commit,
        state = _ref2.state;
    commit('SET_PROMOTER_ID', promoterId);
  }
};