<template>
<!-- begin:: Subheader -->
<div class="kt-subheader   kt-grid__item" id="kt_subheader">
  <div class="kt-subheader__main">
    <h3 class="kt-subheader__title">
      Dashboard </h3>
    <span class="kt-subheader__separator kt-hidden"></span>
    <div class="kt-subheader__breadcrumbs">
      <a href="#" class="kt-subheader__breadcrumbs-home"><i class="flaticon2-shelter"></i></a>
      <span class="kt-subheader__breadcrumbs-separator"></span>
      <a href="" class="kt-subheader__breadcrumbs-link">
        Dashboards </a>
      <span class="kt-subheader__breadcrumbs-separator"></span>
      <a href="" class="kt-subheader__breadcrumbs-link">
        Default Dashboard </a>

    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'SubHeader',
}
</script>

<style scoped></style>
