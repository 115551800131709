export var name = 'subscriptions';
export var state = function state() {
  return {
    postPaymentSubscriptionId: '',
    lastViewedSubscriptionId: ''
  };
};
export var mutations = {
  SET_POST_PAYMENT_SUBSCRIPTION_ID: function SET_POST_PAYMENT_SUBSCRIPTION_ID(state, id) {
    state.postPaymentSubscriptionId = id;
  },
  SET_LAST_VIEWED_SUBSCRIPTION_ID: function SET_LAST_VIEWED_SUBSCRIPTION_ID(state, id) {
    state.lastViewedSubscriptionId = id;
  }
};
export var actions = {
  setPostPaymentSubscriptionId: function setPostPaymentSubscriptionId(_ref, id) {
    var commit = _ref.commit,
        state = _ref.state;
    commit('SET_POST_PAYMENT_SUBSCRIPTION_ID', id);
  },
  setLastViewedSubscriptionId: function setLastViewedSubscriptionId(_ref2, id) {
    var commit = _ref2.commit,
        state = _ref2.state;
    commit('SET_LAST_VIEWED_SUBSCRIPTION_ID', id);
  }
};