export var payment = 'payment';
export var state = function state() {
  return {
    clientSecret: '',
    stripeCustomerId: ''
  };
};
export var mutations = {
  SET_CLIENT_SECRET: function SET_CLIENT_SECRET(state, secret) {
    state.clientSecret = secret;
  },
  SET_CUSTOMER_ID: function SET_CUSTOMER_ID(state, id) {
    state.stripeCustomerId = id;
  }
};
export var actions = {
  setStripeClientSecret: function setStripeClientSecret(_ref, secret) {
    var commit = _ref.commit,
        state = _ref.state;
    commit('SET_CLIENT_SECRET', secret);
  },
  setStripeCustomerId: function setStripeCustomerId(_ref2, id) {
    var commit = _ref2.commit,
        state = _ref2.state;
    commit('SET_CUSTOMER_ID', id);
  }
};