import "core-js/modules/es6.function.name";
import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
export var Role = function Role(_ref) {
  var id = _ref.id,
      name = _ref.name,
      pivot = _ref.pivot,
      title = _ref.title;

  _classCallCheck(this, Role);

  this.id = id;
  this.name = name;
  this.userId = pivot.model_id;
  this.title = title;
};