import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

import de from 'vee-validate/dist/locale/de'

Vue.use(VeeValidate, {
  "nuxti18n": {
    "locale": {
      "de": "de"
    }
  }
})

Validator.localize('de', de)
