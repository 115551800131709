export var name = 'orders';
export var state = function state() {
  return {
    failedPaymentOrder: [],
    subscriptionPaymentFailed: false
  };
};
export var mutations = {
  SET_FAILED_PAYMENT_ORDER: function SET_FAILED_PAYMENT_ORDER(state, order) {
    state.failedPaymentOrder = order;
  },
  SET_SUBSCRIPTION_PAYMENT_FAILED: function SET_SUBSCRIPTION_PAYMENT_FAILED(state, value) {
    state.subscriptionPaymentFailed = value;
  }
};
export var actions = {
  setFailedPaymentOrder: function setFailedPaymentOrder(_ref, order) {
    var commit = _ref.commit,
        state = _ref.state;
    commit('SET_FAILED_PAYMENT_ORDER', order);
  },
  setSubscriptionPaymentFailed: function setSubscriptionPaymentFailed(_ref2, value) {
    var commit = _ref2.commit,
        state = _ref2.state;
    commit('SET_SUBSCRIPTION_PAYMENT_FAILED', value);
  }
};