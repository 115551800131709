export default function ({redirect, route, $auth}) {
  let mayPass = false;
  const nonExistent = -1;
  const user = $auth.user;
  const protectedRoutes = [
    {
      'route': '/adminDashboard',
      'role': 'admin'
    },
    {
      'route': '/reports',
      'role': 'location'
    },
    {
      'route': '/scan',
      'role': 'location'
    }
  ];

  mayPass = !protectedRoutes.includes(route.fullPath);

  if (user) {
    protectedRoutes.forEach((roleNeeded) => {
      if (roleNeeded.route === route.fullPath) {
        //exact match
        mayPass = user.permissions.roles.includes(roleNeeded.role);

        //any location role
        if (roleNeeded.role === 'location') {
          const roleIndex = user.permissions.roles.findIndex(role => {
            const result = role.includes('location-organizer') || role.includes('location-accountant');
            return result;
          });
          mayPass = roleIndex !== nonExistent;
        }
      }
    });
  }

  if (!mayPass) {
    return redirect('/403');
  }
}


