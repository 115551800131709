import "core-js/modules/es6.function.name";
import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
export var TicketType = function TicketType() {
  _classCallCheck(this, TicketType);

  this.id = 96;
  this.name = 'PDF- & Hardticket';
};
export var TicketCategory = function TicketCategory() {
  _classCallCheck(this, TicketCategory);

  this.entity_type_id = 24;
  this.attribute_set_id = 25;
  this.created_at = new Date();
  this.updated_at = new Date();
  this.name = '';
  this.available = true;
  this.status = true;
};