import "core-js/modules/es6.string.link";
import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
export var MediaItem = function MediaItem(_ref) {
  var id = _ref.id,
      title = _ref.title,
      link = _ref.link,
      html = _ref.html;

  _classCallCheck(this, MediaItem);

  this.id = id;
  this.title = title;
  this.link = link;
  this.html = html;
};